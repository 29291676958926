/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Switch,
  Typography,
  FormHelperText,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';

import { firestore } from '../../firebase/firebase';

import CustomSelect from '../../components/forms/custom-elements/CustomSelect';

import AuthService from '../../services/auth.service';

import fullsoonLogo from '../../assets/images/marketplace/fullsoon.png';
import stripeLogo from '../../assets/images/marketplace/stripe.png';
import cashlogyLogo from '../../assets/images/marketplace/cashlogy.png';
import cloverLogo from '../../assets/images/marketplace/clover.png';
import dishopLogo from '../../assets/images/marketplace/dishop.png';
import yavinLogo from '../../assets/images/marketplace/yavin.png';
import neptingLogo from '../../assets/images/marketplace/nepting.png';
import gloryLogo from '../../assets/images/marketplace/glory.png';
import otterLogo from '../../assets/images/marketplace/otter.png';
import biborneLogo from '../../assets/images/marketplace/biborne.png';
import smsLogo from '../../assets/images/marketplace/sms.png';
import pongoLogo from '../../assets/images/marketplace/pongo.png';
import multilanguesLogo from '../../assets/images/marketplace/multilanguage.png';
import uber from '../../assets/images/marketplace/uber.png';
import deliverooLogo from '../../assets/images/marketplace/deliveroo2.png';
import Modules from './Modules';
import useEtablissements from '../../redux/custom-hooks/useEtablissements';


const MarketPlace = () => {
  const [marketplaceModules, setMarketplaceModules] = useState([]);
  const {etablissements}=useEtablissements()
  const [listEquipments, setListEquipments] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [siret, setSiret] = useState(null);

  const [filteredMarketplaceModules, setFilteredMarketplaceModules] = useState([]);

  // Function to retrieve data from Firestore
  const retrieveModulesFromFirestore = async () => {
    const collectionRef = firestore.collection('marketplaceModules');
    const snapshot = await collectionRef.get();
    const modules = snapshot.docs.map((doc) => doc.data());
    console.log('modules', modules);
    setMarketplaceModules(modules);
    console.log('marketplaceModules', marketplaceModules);
  };

  useEffect(() => {
    retrieveModulesFromFirestore();
  }, [selectedRestaurant]);

  useEffect(() => {
    // Filter modules based on the selected siret
    const filteredModules = marketplaceModules.filter((module) => module.siret === siret);
    setFilteredMarketplaceModules(filteredModules);
  }, [siret, marketplaceModules]);

  const handleSelectedRestaurant = async (event) => {
    try {
      const { value } = event.target;

      // Set selected restaurant and siret
      setSelectedRestaurant(value);
      setSiret(value);

      // Check if the document already exists for the selected siret in 'marketplaceModules'
      const collectionRef = firestore.collection('marketplaceModules');
      const docRef = collectionRef.doc(value);
      const docSnapshot = await docRef.get();

      if (!docSnapshot.exists) {
        // If the document doesn't exist, create it with default data
        const defaultData = {
          siret: value,
          users: [
            {
              keen_user_id: '',
              modules: [
                {
                  id_module: uuidv4(),
                  keen_user_id: '',
                  module_name: 'Uber Eats',
                  category: 'Marketing',
                  image: uber,
                  description:
                    'Lorem dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                  store_id: '',
                  uber_rate: false,
                  uber_developer: false,
                  ring_new_command: false,
                  product_search_by_name: false,
                  selected_pos: '',
                  status: false,
                },
                {
                  id_module: uuidv4(),
                  keen_user_id: '',
                  module_name: 'Deliveroo',
                  category: 'Marketing',
                  image: deliverooLogo,
                  description:
                    'Lorem dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                  location_id: '',
                  mode_deliveroo_developer: false,
                  auto_accept_commands: false,
                  status: false,
                },
                {
                  id_module: uuidv4(),
                  keen_user_id: '',
                  module_name: 'Dishop Connector',
                  category: 'Marketing',
                  image: dishopLogo,
                  description:
                    'Lorem dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                  status: false,
                },
                {
                  id_module: uuidv4(),
                  keen_user_id: '',
                  module_name: 'Dishop Light',
                  category: 'Marketing',
                  image: dishopLogo,
                  description:
                    'Lorem dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                  status: false,
                },
              ],
            },
          ],
        };

        await docRef.set(defaultData);
      }
      retrieveModulesFromFirestore();
    } catch (error) {
      console.error('Error handling selected restaurant:', error);
    }
  };

 

  const retrieveEquippmentsBySiret = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}/api/v1/restos/findOneRestoWithKeenIds/${selectedRestaurant}`,
      )
      .then((response) => {
        setListEquipments(response.data.keen_ids);
        console.log(response.data);
        console.log('listEquipements', listEquipments);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveEquippmentsBySiret();
  }, [selectedRestaurant]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const handleSwitchChange = (moduleId, moduleSiret) => {
    setMarketplaceModules((prevModules) => {
      const updatedModules = prevModules.map((restaurant) => {
        if (restaurant.siret === moduleSiret) {
          return {
            ...restaurant,
            users: restaurant.users.map((user) => {
              return {
                ...user,
                modules: user.modules.map((module) => {
                  if (module.id_module === moduleId) {
                    return {
                      ...module,
                      status: !module.status,
                    };
                  }
                  return module;
                }),
              };
            }),
          };
        }
        return restaurant;
      });

      // Update Firestore document
      const collectionRef = firestore.collection('marketplaceModules');
      const docRef = collectionRef.doc(moduleSiret);

      docRef.update({
        users: updatedModules
          .find((restaurant) => restaurant.siret === moduleSiret)
          .users.map((user) => ({ ...user, modules: user.modules })),
      });

      return updatedModules;
    });
  };


  const handleSettings = (item) => {
    setSelectedModule(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // CONFIGURE PAGINATION
  // const allModules = marketplaceModules.flatMap((item) => item.modules);

  // const itemsPerPage = 6;
  // const totalModules = allModules.length;
  // const totalPages = Math.ceil(totalModules / itemsPerPage);

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  // const currentModules = allModules.slice(startIndex, endIndex);

  // const storeInitialData = async () => {
  //   const collectionRef = firestore.collection('marketplaceModules');

  //   // Use forEach to iterate over the modules and add them to Firestore
  //   initialMarketplaceModules.forEach(async (module) => {
  //     // Use module_name as the document ID
  //     const docRef = collectionRef.doc(module.siret);

  //     // Set the data for the document
  //     await docRef.set(module);
  //   });
  // };

  // // Call the function to store initial data
  // storeInitialData();

  const modulesWithoutPos = filteredMarketplaceModules
    .flatMap((restaurant) => restaurant.users)
    .flatMap((user) => user.modules)
    .filter((module) => !module.selected_pos);

  const modulesWithPos = filteredMarketplaceModules
    .flatMap((restaurant) => restaurant.users)
    .flatMap((user) => user.modules)
    .filter((module) => module.selected_pos);

  console.log('modulesWithoutPos', modulesWithoutPos);
  console.log('modulesWithPos', modulesWithPos);
  console.log('marketplaceModules', marketplaceModules);

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '10px',
          mx: 5,
          my: 1,
        }}
      >
        <Pagination
        // count={totalPages} page={currentPage} onChange={handlePageChange}
        />
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <FormHelperText>Sélectionner un restaurant</FormHelperText>

          <FormControl sx={{ m: 2, minWidth: 250 }}>
            <CustomSelect
              labelId="selectedRestaurant"
              id="selectedRestaurant"
              name="selectedRestaurant"
              value={selectedRestaurant}
              onChange={handleSelectedRestaurant}
              fullWidth
              size="small"
              label="selectedRestaurant"
            >
              <MenuItem sx={{ fontSize: 12 }}>Tous les restaurants</MenuItem>
              {etablissements.map((etablissement) => (
                <MenuItem
                  sx={{ fontSize: 12, textTransform: 'uppercase' }}
                  key={etablissement.restos.id}
                  value={etablissement.restos.siret}
                >
                  {etablissement.restos.pseudo}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </Grid>
      </Paper>
      <Grid sx={{ justifyContent: 'center' }} container>
        {!selectedRestaurant ? (
          <Typography sx={{ m: 2 }}>Veuillez selectionner un restaurant</Typography>
        ) : (
          <>
            <Typography sx={{ m: 2, width: '100%', textAlign: 'center' }}>
              Veuillez configurer un module
            </Typography>

            {filteredMarketplaceModules?.map(
              (restaurant) =>
                // restaurant.users?.map((user) =>
                // user.modules?.map((module) => (
                modulesWithoutPos.map((module) => (
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '10px',
                      background: 'white',
                      boxShadow: 1,
                      p: 3,
                      m: 1,
                      opacity: module.status ? 1 : 0.7,
                    }}
                    item
                    xs={5.5}
                  >
                    <Grid sx={{ pr: 2 }}>
                      <Avatar sx={{ width: 66, height: 66 }} src={module.image} />
                    </Grid>
                    <Grid>
                      <Grid sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                          {module.module_name}
                        </Typography>
                        {module.selected_pos && (
                          <Chip
                            sx={{
                              ml: 3,
                              borderRadius: '6px',
                            }}
                            size="small"
                            variant=""
                            label={module.selected_pos}
                          />
                        )}
                        <Chip
                          sx={{
                            ml: 1,
                            borderRadius: '6px',
                            backgroundColor:
                              module.category === 'Marketing'
                                ? (theme) => theme.palette.primary.main
                                : module.category === 'Other'
                                ? (theme) => theme.palette.secondary.main
                                : module.category === 'Commandes'
                                ? (theme) => theme.palette.error.main
                                : (theme) => theme.palette.secondary.main,
                            color: '#fff',
                          }}
                          size="small"
                          label={module.category}
                        />
                        <SettingsIcon
                          sx={{
                            borderRadius: '5px',
                            p: '1px',
                            m: '2px',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleSettings(module)}
                        />
                      </Grid>
                      <Typography variant="body2">{module.description}</Typography>
                    </Grid>
                    <Switch
                      checked={module.status}
                      onChange={() => handleSwitchChange(module.id_module, siret)}
                    />
                  </Grid>
                )),
              // ),
            )}
          </>
        )}
      </Grid>
      <Modules
        siret={siret}
        modulesWithPos={modulesWithPos}
        listEquipments={listEquipments}
        setListEquipments={setListEquipments}
        selectedModule={selectedModule}
        setselectedModule={setSelectedModule}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleSwitchChange={handleSwitchChange}
      />
    </>
  );
};

export default MarketPlace;
