import { Box, Typography } from '@material-ui/core';
import React from 'react'
import EmptyDataIcon from '../icons/EmptyDataIcon';


const EmptyData = () => {
  return (
    <Box sx={{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      flexDirection:"column",
      padding: '40px 0',
    }}>
      <Box sx={{
        "& path":{
          fill:t=>t.palette.mainColor
        }
      }}>
       <EmptyDataIcon/> 
      </Box>
      
      <Typography
              style={{
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: '500',
                fontStyle: 'italic',
                mt:"20px",
                opacity:.7
              }}
            >
              Aucune donnée disponible <br/> pour le moment
            </Typography>
    </Box>
    
  )
}

export default EmptyData