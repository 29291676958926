import { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app';
import { useLocation } from 'react-router';
import authService from '../../../services/auth.service';
import { firestore } from '../../../firebase/firebase';

const useCartes = () => {
    const db = firestore; // Récupérer une référence à Firestore
    const [campagnes, setCampagnes] = useState([]);
    const [sizes3, setsizes3] = useState(null);
    const [loading, setloading] = useState(true);
    const currentUser=authService.getCurrentUser()
    const {pathname}=useLocation()
    useEffect(() => {
      setsizes3(null)
    }, [currentUser?.id])
    
    const getCampagnes = async () => {
        try {
          const snapshot = await db
            .collection('CartesCampagnes')
            .where('franchiseId', '==', currentUser?.id)
            .get();
          const campagnesArray = [];
          snapshot.forEach((doc) => {
            const campagne = { id: doc.id, ...doc.data() };
            campagnesArray.push(campagne);
          });
          setCampagnes(campagnesArray);
          return campagnesArray;
        } catch (error) {
          console.error('Erreur lors de la récupération des campagnes : ', error);
          return [];
        }
      };
      useEffect(() => {
        if(currentUser?.id && [
          '/campagne/MesCartes',
          '/campagne/MesProduits',
          '/campagne/DeploiementCarte',
          '/config/mesTailles',
          '/config/mesImprimantes',
        ].includes(pathname)){
          getCampagnes()  
        }
      }, [currentUser?.id,pathname]
        )
      const lastImportedCamapgne=campagnes?.sort((a,b)=>b.createdAt - a.createdAt)[0]
      const cardURL=lastImportedCamapgne?.url
    const getSizes=async()=>{
      setloading(true)
      try {
        const campagnePath = `CartesCampagnes/${currentUser?.id}/${cardURL}`;
          const campagneRef = firebase.storage().ref().child(campagnePath);
          const campagneSnapshot = await campagneRef.getDownloadURL();
          const campagneResponse = await fetch(campagneSnapshot);
          const campagneContent = await campagneResponse.json();
          const campagneObject=campagneContent?.orderJson    
          const camapagnesSizes=[
            {
              label_size: campagneObject?.product_size_1,
              id_size: 1,
              name_size: 'junior',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_2,
              id_size: 2,
              name_size: 'senior',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_3,
              id_size: 3,
              name_size: 'mega',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_4,
              id_size: 4,
              name_size: 'size1',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_5,
              id_size: 5,
              name_size: 'size2',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_6,
              id_size: 6,
              name_size: 'size3',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_7,
              id_size: 7,
              name_size: 'size4',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_8,
              id_size: 8,
              name_size: 'size5',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_9,
              id_size: 9,
              name_size: 'size6',
              description: "",
              size_displayed: true,
            },
            {
              label_size: campagneObject?.product_size_10,
              id_size: 10,
              name_size: 'size7',
              description: "",
              size_displayed: true,
            },
          ]  
          setsizes3(camapagnesSizes?.map(cs=>({
            ...cs, 
            size_displayed:campagneObject?.items?.some(el=>el?.allSizes?.find(size=>size?.name_size===cs?.name_size && size?.size_enabled))
          })))
      } catch (error) {
        console.log("error while getting camapgnes", error)
      }
      setloading(false)
      
    }
    useEffect(() => {
      if(cardURL && currentUser?.id){
        getSizes()
      }
    }, [cardURL, currentUser?.id])
      return {campagnes, sizes3, loading}
}

export default useCartes