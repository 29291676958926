import React from 'react';
import { Box } from '@material-ui/core';
import Dashboard from './Dashboard';
import PageContainer from '../../components/container/PageContainer';

const RealtimeDashboard = () => {
  return (
    <PageContainer title="Realtime dashboard" description="Realtime dashboard">
      <Box
      >
        <Dashboard/>
      </Box>
    </PageContainer>
  );
};

export default RealtimeDashboard;
