import { Box, Button, Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropsType from 'prop-types';
import useEtablissements from '../../../../redux/custom-hooks/useEtablissements';
import Searchbar from '../../../../components/custum-elements/Searchbar';

const RestauListPopup = ({ openPopup, setOpenPopup, setSelectedRestau, selectedRestau }) => {
  const { etablissements } = useEtablissements();
  const [query, setQuery] = useState('');
  const onClose = () => {
    setOpenPopup(false);
  };
  useEffect(() => {
   setQuery("")
  }, [openPopup])
  
  const filtredElements = () => {
    // return etablissements
    return etablissements?.filter((el) =>
      el?.restos?.nom?.toLowerCase().includes(query.toLowerCase()),
    );
  };
  return (
    <Dialog sx={{
      "&  .MuiPaper-root":{
        width: { xs: '100%', sm: '400px' },
      }
    }} open={openPopup} onClose={onClose}>
      <Box
        sx={{
          width: { xs: '100%', sm: '400px' },
          maxHeight: 'calc(100vh - 68px)',
          display: 'flex',
          flexDirection: 'column',
         "& .searchbarContainer":{
                    backgroundColor:"transparent !important",
                    borderRadius:"0 !important",
                    borderBottom:"1px solid lightgray",
                  }
        }}
        className="productsListContainer"
      >
        <Searchbar elementToSearch="un restaurant" query={query} setQuery={setQuery} />
        <Box
          sx={{
            overflow: 'hidden',
            overflowY: 'auto',
            height: '100%',
            flexGrow: 1,
          }}
          className="productList scrollableBox"
        >
          <Box
                onClick={() => {
                  setSelectedRestau(null);
                  onClose();
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '10px',
                  backgroundColor: !selectedRestau && ((t) => t.palette.mainColor),
                  color: !selectedRestau && 'white',
                  cursor: 'pointer',
                  borderBottom: '1px solid lightgray',
                  transition: 'all .4s ease',
                  '&:hover': {
                    backgroundColor: (t) => t.palette.mainColor,
                    color: 'white',
                  },
                }}
                className="productItem"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                  className="left"
                >
                  <Box className="name">Tous les restaurants</Box>
                </Box>
              </Box>
          {filtredElements()?.length !== 0 ? (
            filtredElements()?.map((el, index) => (
              <Box
                onClick={() => {
                  setSelectedRestau(el);
                  onClose();
                }}
                key={el?.restos?.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '10px',
                  backgroundColor: selectedRestau?.restos?.id===el?.restos?.id && ((t) => t.palette.mainColor),
                  color: selectedRestau?.restos?.id===el?.restos?.id && 'white',
                  cursor: 'pointer',
                  borderBottom: index !== etablissements?.length - 1 && '1px solid lightgray',
                  transition: 'all .4s ease',
                  '&:hover': {
                    backgroundColor: (t) => t.palette.mainColor,
                    color: 'white',
                  },
                }}
                className="productItem"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                  className="left"
                >
                  <Box className="name">{el?.restos?.nom}</Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                fontStyle: 'italic',
                fontSize: '14px',
                textAlign: 'center',
                padding: '20px',
              }}
            >
              Pas de données à afficher
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'flex-end',
            padding: '12px 20px',
            borderTop: '1px solid lightgray',
          }}
          className="bottom"
        >
          <Button onClick={onClose} variant="outlined">
            Annuler
          </Button>
          {/* <Button
            // onClick={handlesaveChanges}
              // disabled={loading || selecteCards.length === 0}
              sx={{
                backgroundColor: (theme) => theme.palette.success.main,
              }}
              
              variant="contained"
            >
              Enregistrer
            </Button> */}
        </Box>
      </Box>
    </Dialog>
  );
};
RestauListPopup.propTypes = {
  openPopup: PropsType.any.isRequired,
  setOpenPopup: PropsType.any.isRequired,
  setSelectedRestau: PropsType.any.isRequired,
  selectedRestau: PropsType.any.isRequired,
};
export default RestauListPopup;
