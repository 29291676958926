import http from "../http-common";
import { privateReqApiBackoffice } from "../requestMethods";

const getAll = () => {
  return http.get("/franchises");
};

const get = id => {
  return privateReqApiBackoffice.get(`/api/franchises/V2/findOneProcaisse/${id}`);
};

const getfranchiseprocaisse = id => {
  return http.get(`/franchiseprocaisse/${id}`);
};

const create = data => {
  return http.post("/franchises", data);
};

const update = (id, data) => {
  return http.put(`/franchises/${id}`, data);
};

const remove = id => {
  return http.delete(`/franchises/${id}`);
};

const removeAll = () => {
  return http.delete(`/franchises`);
};

export default {
  getAll,
  get,
  getfranchiseprocaisse,
  create,
  update,
  remove,
  removeAll
};
