import { Box } from '@material-ui/core';
import React from 'react';
import PropsType from 'prop-types';
import { useSelector } from 'react-redux';
import { ConvertirMonetaire } from '../../../utility/utilityFunctions';
import useAnnulations from '../custom-hooks/useAnnulations';

const Annulations = ({date, forDay, currentUser}) => {
   
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  const { annulations } = useAnnulations(currentUser, date, forDay);
  const {
    loading,
    // error,
    data
  } = annulations
  return (
    <Box className="promotionContainer">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        className="promotionTop"
      >
        <Box
          sx={{
            color: (t) => t.palette.textDark,
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          Annulations{' '}
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '14px',
            }}
            className="nbreCommandes"
          >
            {loading ? <Box>Loading...</Box> : data?.nombrescommandes} Commandes
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Box
            sx={{
              color: (t) => t.palette.textDark,
              fontSize: '17px',
              fontWeight: 500,
            }}
            className="percent"
          >
            {ConvertirMonetaire(currencyCode, currencySymbol, data?.totalTtc)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
Annulations.propTypes = {
    date: PropsType.any.isRequired,
    forDay: PropsType.any.isRequired,
    currentUser: PropsType.any.isRequired,
  };
export default Annulations;
